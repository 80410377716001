<template lang="pug">
    Aside(:ativo="contents !== null" @close="$emit('setContent', null)")#presidenteContent
        Imagem(:contents="contents.image", v-if="contents.image").img
        h3 {{ contents.name.pt }} - {{ contents.role.pt }}
        h6 {{ contents.period.pt }}
        .content(v-html="parseContent(contents.content.pt)")
</template>

<script>
import Aside from '@components/Aside/Aside'
import { components, props, parseContent } from '@/mixins/components'

export default {
    name: "component-presidente-content",
    props,
    components: Object.assign({}, components, {
        Aside,
    }),
    methods: {
        parseContent,
    },
}
</script>

<style lang="stylus" scoped src="@stylus/defaultTextos.styl"></style>
<style lang="stylus" scoped src="./PresidenteContent.styl"></style>
