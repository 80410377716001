import { render, staticRenderFns } from "./PresidenteContent.vue?vue&type=template&id=473285b8&scoped=true&lang=pug&"
import script from "./PresidenteContent.vue?vue&type=script&lang=js&"
export * from "./PresidenteContent.vue?vue&type=script&lang=js&"
import style0 from "@stylus/defaultTextos.styl?vue&type=style&index=0&id=473285b8&lang=stylus&scoped=true&"
import style1 from "./PresidenteContent.styl?vue&type=style&index=1&id=473285b8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473285b8",
  null
  
)

export default component.exports